import React from 'react';
import addnew from '../images/addnew.webp';
import '../App.css'; // Fichier de styles pour le composant ProductPreview

const ProductPreview = () => {
    return (
        <div className="product-preview">
            <img src={addnew} alt="Product" className="product-image" />
            <h3 className="product-name">AddNew</h3>
            <p className="product-description">Réseau social d'echange de contact pour augmenter vos vues sur statut whatsapp</p>
        </div>
    );
};

export default ProductPreview;
