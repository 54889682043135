import logo from './images/logoIsiSoft.webp';
import illustration from './images/illustration.webp';

import './App.css';
import ProductPreview from './components/product_card';

function App() {
  return (
    <div className="App">
      <header className="App-header">

        <img src={logo} alt="logo" />


      </header>
      <main className="App-content">
        <div className="hero-section">
          <img src={illustration} alt="Illustration" className="illustration" />
          <h1>Bienvenue sur IsiSoft Company</h1>
          <p>Découvrez notre startup informatique spécialisée dans le développement d'applications, le graphisme et les sites web.</p>
        </div>
        <div className="about-section">
          <h2>À propos de nous</h2>
          <p>Nous sommes une startup passionnée par la création et l'innovation technologique. Notre équipe talentueuse se consacre à la conception et au développement d'applications mobiles et web, à la création de graphismes attrayants et à la conception de sites web modernes et fonctionnels.</p>
          <p>Nous nous efforçons de fournir des solutions sur mesure pour répondre aux besoins de nos clients. Que vous ayez besoin d'une application mobile, d'une identité visuelle percutante ou d'un site web professionnel, nous sommes là pour vous accompagner tout au long du processus.</p>
          <p>Notre objectif est de créer des expériences numériques exceptionnelles qui allient esthétique, convivialité et performances. Nous sommes fiers de notre engagement envers la qualité et de notre approche axée sur la satisfaction de nos clients.</p>
          <h2>Nos produits</h2>
          <ProductPreview />
        </div>


      </main>
      <footer className="App-footer">

        <div className="social-icons">
          <a href="https://www.facebook.com/IsiSoftCompany/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook"></i>
          </a>
          <a href="https://twitter.com/IsisoftC" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
          </a>
          {/* <a href="https://www.instagram.com/votrecompte" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a> */}
          <a href="https://wa.me//22965783098" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-whatsapp"></i>
          </a>
          {/* <a href="https://www.tiktok.com/@votrecompte" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-tiktok"></i>
          </a> */}
          <a href="https://www.linkedin.com/in/isisoft-company-400466186/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin"></i>
          </a>
          <a href="https://www.youtube.com/@IsiSoftCompany" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-youtube"></i>
          </a>
        </div>


        <div className="App-footer-links">
          <a href="/conditions-utilisation-addnew">Conditions générales d'utilisation</a>
          <a href="/conditions-utilisation-addnew">Conditions générales d'utilisation AddNew</a>
          <a href="/conditions-utilisation-addnew">Politique de confidentialité</a>

        </div>

        <p style={{ fontSize: '14px' }}>&copy; 2023 IsiSoft Company. Tous droits réservés.</p>
      </footer>



    </div>
  );
}

export default App;
