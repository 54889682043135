import React from 'react';

function AddNewConditions() {
    const pageStyle = {
        marginLeft: '10vh',
        marginRight: '10vh',
        fontSize: '18px'
    };

    const headingStyle = {
        fontSize: '24px',
        fontWeight: 'bold',


    };
    const headingStyle2 = {
        fontSize: '48px',
        fontWeight: 'bold',
        color: '0xFF03989E'

    };
    return (
        <div>
            <div style={pageStyle}>
                <h1 style={headingStyle2}>Conditions Générales d'Utilisation de l'application AddNew </h1>
                <p>Voici les conditions générales d'utilisation de l'application AddNew :</p>

                <p>Veuillez lire attentivement ces conditions avant d'utiliser l'application AddNew. En utilisant cette application, vous acceptez les termes et conditions énoncés ci-dessus.</p>
                <p>Pour toute question ou demande concernant ces conditions d'utilisation, veuillez nous contacter à l'adresse email suivante : contact@isisoftcompany.com</p>
                <h2 style={headingStyle}>Dernière mise à jour: 14 Juin 2023 13:12</h2>
                <h2 style={headingStyle}>Article 1</h2>
                <p>Définitions</p>
                <p>On désignera par la suite:</p>
                <ul>
                    <li style={{ fontSize: '16px' }}>'Application' : l'application AddNew et l'ensemble de ses pages et écrans</li>
                    <li style={{ fontSize: '16px' }}>'Editeur' : IsiSoft Company</li>
                    <li style={{ fontSize: '16px' }}>'Utilisateur' : Le mobinaute visitant et utilisant l'application</li>
                </ul>

                <h2 style={headingStyle}>Article 2: Mentions imposées par la loi de confiance dans l'économie numérique et objet de l'application</h2>
                <p>Conformément à la loi de confiance dans l'économie numérique, nous vous fournissons les mentions légales requises pour l'utilisation de notre application :</p>
                <ol>
                    <li style={{ fontSize: '16px' }}>Identité de l'éditeur de l'application :
                        <ul>
                            <li>IsiSoft Company</li>
                            <li>Cotonou, Benin</li>
                            <li>+229 65783098</li>
                            <li>isisoftcompany777@gmail.com</li>
                            <li>ifu: 0202238278867</li>
                        </ul>
                    </li>
                    <li style={{ fontSize: '16px' }}>Objet de l'application :
                        <ul>
                            <li>Notre application a pour objectif de permettre aux utilisateurs d'obtenir de nouveaux contacts (numéro de téléphone).</li>
                            <li>Elle offre [préciser les fonctionnalités et services disponibles].</li>
                            <li>L'application est destinée à être utilisée par un public ayant au moins 13 ans.</li>
                        </ul>
                    </li>
                    <li style={{ fontSize: '16px' }}>Collecte et utilisation des données :
                        <p>Lors de l'utilisation de notre application, certaines informations personnelles peuvent être collectées conformément à notre politique de confidentialité. Nous nous engageons à respecter la confidentialité et la protection des données de nos utilisateurs. Pour plus d'informations sur la collecte, l'utilisation et la protection des données, veuillez consulter notre politique de confidentialité.</p>
                    </li>
                    <li style={{ fontSize: '16px' }}>Propriété intellectuelle :
                        <p>Tous les contenus présents dans l'application, tels que les textes, les images, les graphiques, les logos, les icônes, les vidéos, les sons et les logiciels, sont la propriété exclusive de [Nom de l'entreprise/organisation] ou de ses partenaires et sont protégés par les lois sur la propriété intellectuelle. Toute utilisation, reproduction ou modification des contenus sans autorisation préalable est strictement interdite.</p>
                    </li>
                    <li style={{ fontSize: '16px' }}>Responsabilité :
                        <p>Nous mettons tout en œuvre pour garantir l'exactitude et la qualité des informations présentes dans notre application. Cependant, nous ne pouvons pas être tenus responsables des éventuelles erreurs, omissions ou inexactitudes des informations fournies. L'utilisation de l'application se fait sous la responsabilité de l'utilisateur.</p>
                    </li>
                    <li style={{ fontSize: '16px' }}>Modification des conditions générales d'utilisation :
                        <p>Nous nous réservons le droit de modifier les présentes conditions générales d'utilisation à tout moment. Les utilisateurs seront informés de ces modifications via l'application ou tout autre moyen jugé approprié. Il est conseillé de consulter régulièrement les conditions générales d'utilisation pour rester informé des éventuelles modifications.</p>
                    </li>
                </ol>

                <h2 style={headingStyle}>Article 3 - Compte dans l'application</h2>
                <p>L'utilisateur créant un compte sur l'application (membre) a la possibilité d'y accéder en se connectant grâce aux identifiants fournis lors de son inscription.</p>
                <p>L'utilisateur est entièrement responsable de la protection du mot de passe qu'il a choisi. Il est encouragé à utiliser des mots de passe complexes. En cas d'oubli de mot de passe, le membre a la possibilité d'en générer un nouveau. Ce mot de passe constitue la garantie de la confidentialité des informations contenues dans son compte et l'utilisateur s'interdit donc de le transmettre ou de le communiquer à un tiers. A défaut, l'éditeur de l'application ne pourra être tenu pour responsable des accès non autorisés au compte d'un utilisateur. L'éditeur se réserve le droit exclusif de supprimer le compte de tout membre qui aurait contrevenu aux présentes conditions générales (notamment mais sans que cet exemple n'ait un quelconque caractère exhaustif, lorsque le membre aura fourni sciemment des informations erronées lors de son inscription) ou encore tout compte inactif depuis au moins une année. Ladite suppression ne sera pas susceptible de constituer un dommage pour le membre exclu qui ne pourra prétendre à aucune indemnité de ce fait. Cette exclusion n'est pas exclusive de la possibilité, pour l'éditeur, d'entreprendre des poursuites d'ordre judiciaire à l'encontre du membre, lorsque les faits l'auront justifié. Chaque membre est libre de fermer son compte. Pour cela, le membre doit adresser un e-mail à l'éditeur indiquant qu'il souhaite supprimer son compte. Aucune récupération de ses données ne sera alors possible.</p>

                <h2 style={headingStyle}>Article 4 - Exonération de la responsabilité de l'éditeur dans le cadre de l'exécution du présent contrat</h2>
                <p>En cas d'impossibilité d'accès à l'application, en raison de problèmes techniques ou de toutes natures, l'utilisateur ne pourra se prévaloir d'un dommage et ne
                    pourra prétendre à aucune indemnité.
                    Les liens hypertextes présents sur l'application peuvent renvoyer sur d'autres applications ou sur des sites internet et la responsabilité de l'éditeur de l'application
                    ne saurait être engagée si le contenu de ces sites et applications contrevient aux législations en vigueur. De même la responsabilité de l'éditeur ne saurait être
                    engagée si l'utilisation de ces sites ou applications, par l'utilisateur, lui causait un préjudice.</p>

                <h2>Article 5 - Droits de propriété intellectuelle relatifs aux éléments de l'application</h2>
                <p>1. Contenus de l'application :</p>
                <p>Tous les contenus présents dans notre application, tels que les textes, les images, les graphiques, les logos, les icônes, les vidéos, les sons et les logiciels, sont la propriété exclusive de l'Editeur ou de ses partenaires et sont protégés par les lois sur la propriété intellectuelle. Ces contenus sont protégés par des droits d'auteur, des marques déposées et d'autres lois relatives à la propriété intellectuelle.</p>
                <p>2. Utilisation autorisée :</p>
                <p>L'utilisation de l'application ne confère aucun droit de propriété sur les contenus qui y sont présents. Vous êtes autorisé à utiliser l'application et ses contenus uniquement dans le cadre de l'utilisation normale de l'application et conformément aux présentes conditions générales d'utilisation. Toute autre utilisation, reproduction, distribution, modification ou diffusion des contenus sans autorisation préalable écrite de l'Editeur est strictement interdite.</p>
                <p>3. Marques commerciales :</p>
                <p>Les noms, logos et marques présents dans l'application sont des marques commerciales déposées ou non de l'Editeur ou de tiers. Toute utilisation de ces marques sans autorisation préalable écrite de leur propriétaire respectif est strictement interdite.</p>
                <p>4. Contenus générés par les utilisateurs :</p>
                <p>Dans le cas où l'application permet aux utilisateurs de générer du contenu, tels que des commentaires, des avis, des publications ou des téléchargements, vous accordez à l'Editeur une licence non exclusive, mondiale, perpétuelle, irrévocable, libre de redevance et transférable pour utiliser, reproduire, distribuer, afficher publiquement et créer des œuvres dérivées de ces contenus dans le cadre de l'exploitation de l'application.</p>
                <p>5. Signalement de violations :</p>
                <p>Si vous pensez que des contenus présents dans notre application portent atteinte à vos droits de propriété intellectuelle, veuillez nous contacter immédiatement aux coordonnées fournies. Nous examinerons les signalements de violations de droits de propriété intellectuelle et prendrons les mesures appropriées conformément à la législation en vigueur.</p>
                <p>6. Responsabilité :</p>
                <p>L'Editeur décline toute responsabilité en cas de violation des droits de propriété intellectuelle d'un tiers résultant de l'utilisation de l'application par un utilisateur. Chaque utilisateur est responsable de s'assurer qu'il dispose des droits nécessaires pour utiliser et publier tout contenu généré par l'utilisateur dans l'application.</p>
                <p>Veuillez respecter les droits de propriété intellectuelle des contenus présents dans notre application. Toute utilisation non autorisée de ces contenus constitue une violation des droits de propriété intellectuelle et peut entraîner des poursuites légales.</p>

                <h2>Article 6 - La limitation des responsabilités </h2>

                <p>1. Utilisation de l'application :<br />
                    L'utilisation de notre application se fait sous votre propre responsabilité. Nous mettons tout en œuvre pour garantir que l'application fonctionne de manière optimale, mais nous ne pouvons pas garantir son accessibilité continue, son absence d'erreurs ou son fonctionnement ininterrompu. Vous acceptez d'utiliser l'application à vos propres risques.
                    <br /><br />
                    2. Contenus et informations :
                    Nous nous efforçons de fournir des contenus et des informations précis et à jour dans notre application. Cependant, nous ne pouvons pas garantir l'exactitude, l'exhaustivité ou la fiabilité de ces contenus et informations. Vous reconnaissez que les contenus et informations présents dans l'application sont fournis à titre informatif uniquement et ne constituent pas des conseils professionnels ou des recommandations. Vous assumez la responsabilité de l'utilisation des contenus et informations présents dans l'application.
                    <br /><br />
                    3. Liens vers des sites tiers :
                    Notre application peut contenir des liens vers des sites web de tiers. Ces liens sont fournis uniquement pour votre commodité et ne constituent pas une approbation ou une recommandation de ces sites web. Nous déclinons toute responsabilité quant au contenu, à l'exactitude et à la fiabilité des sites web tiers. Vous accédez à ces sites web tiers à vos propres risques.
                    <br /><br />
                    4. Interruptions ou dysfonctionnements :
                    Nous déclinons toute responsabilité en cas d'interruptions, de retards, de dysfonctionnements techniques ou de tout autre problème lié à l'utilisation de l'application. Nous ne serons pas responsables des dommages directs, indirects, consécutifs ou spéciaux résultant de tels problèmes.
                    <br /><br />
                    5. Limitation de responsabilité :
                    Dans les limites autorisées par la loi applicable, nous déclinons toute responsabilité pour les dommages directs, indirects, consécutifs, spéciaux ou exemplaires résultant de l'utilisation de l'application ou de l'impossibilité de l'utiliser, y compris les pertes de données, les pertes de profits, les interruptions d'activité ou tout autre préjudice commercial, même si nous avons été informés de la possibilité de tels dommages.
                    <br /><br />
                    6. Exclusion de garantie :
                    Dans les limites autorisées par la loi applicable, nous déclinons toutes garanties, expresses ou implicites, concernant l'application et son contenu. Nous ne garantissons pas que l'application sera exempte d'erreurs, de virus ou d'autres éléments nuisibles. Nous ne faisons aucune déclaration ni ne donnons aucune garantie quant à l'exactitude, l'exhaustivité, la fiabilité ou l'adéquation à un usage particulier des contenus présents dans l'application.</p>

                <h2>Article 7 - Accès à l'application par connexion Internet </h2>
                <p>La responsabilité de l'éditeur ne peut être engagée en raison d'une indisponibilité technique de la connexion, qu'elle soit due notamment à un cas de force
                    majeure, à une maintenance, à une mise à jour, à une modification, à une intervention de l'hébergeur, à une grève interne ou externe, à une panne de réseau, à
                    une coupure d'alimentation électrique, ou encore à une mauvaise configuration ou utilisation du périphérique de l'utilisateur.</p>

                <h2>Article 8 - Clauses diverses</h2>
                <p>Les présentes conditions générales sont soumises à l'application du droit . Elles peuvent être modifiées à tout moment par l'éditeur ou son mandataire.
                    Les conditions générales applicables à l'utilisateur sont celles en vigueur au jour de son achat ou de sa connexion sur l'application. L'éditeur s'engage bien
                    évidemment à conserver toutes ses anciennes conditions générales et à les faire parvenir à tout utilisateur qui en ferait la demande. Sauf dispositions d'ordre
                    public, tous litiges qui pourraient survenir dans le cadre de l'exécution des présentes conditions générales pourront avant toute action judiciaire être soumis à
                    l'appréciation de l'éditeur en vue d'un règlement amiable. Il est expressément rappelé que les demandes de règlement amiable ne suspendent pas les délais
                    ouverts pour intenter les actions judiciaires. Sauf disposition contraire, d'ordre public, toute action judiciaire relative à l'exécution du présent contrat devra être
                    soumise à la compétence des juridictions du ressort de la Cour d'appel saisie.</p>

                <h2>Article 9 -  Utilisateur de fichiers déposés sur le périphérique</h2>
                <p>1. Responsabilité de l'utilisateur :
                    Lorsque vous utilisez notre application, vous pouvez être amené à déposer des fichiers sur votre périphérique, tels que des documents, des images ou d'autres types de fichiers. Vous assumez la responsabilité de ces fichiers et de leur utilisation. Nous déclinons toute responsabilité quant au contenu, à l'origine, à la légalité ou à la sécurité de ces fichiers.
                    <br /><br />
                    2. Protection des données personnelles :
                    Lorsque vous déposez des fichiers sur votre périphérique à l'aide de notre application, veuillez prendre en compte les lois et réglementations applicables relatives à la protection des données personnelles. Vous êtes responsable de vous assurer que vous avez les droits nécessaires pour déposer et stocker ces fichiers sur votre périphérique.
                    <br /><br />
                    3. Confidentialité des fichiers :
                    Nous nous engageons à respecter votre vie privée et à protéger la confidentialité des fichiers que vous déposez sur votre périphérique à l'aide de notre application. Toutefois, nous ne pouvons pas garantir la sécurité absolue de ces fichiers, car la sécurité des périphériques peut être influencée par des facteurs externes. Vous acceptez d'utiliser notre application en tenant compte de ces risques.
                    <br /><br />
                    4. Utilisation des fichiers :
                    Vous êtes autorisé à utiliser les fichiers que vous déposez sur votre périphérique uniquement dans le cadre de l'utilisation normale de notre application. Toute autre utilisation, reproduction, distribution ou modification des fichiers sans autorisation préalable écrite de leur propriétaire est strictement interdite.
                    <br /><br />
                    5. Suppression des fichiers :
                    Vous êtes responsable de la gestion et de la suppression des fichiers que vous déposez sur votre périphérique à l'aide de notre application. Nous ne sommes pas responsables de la suppression ou de la perte de ces fichiers. Veuillez sauvegarder vos fichiers régulièrement pour éviter toute perte de données.
                    <br /><br />
                    6. Exclusion de responsabilité :
                    Dans les limites autorisées par la loi applicable, nous déclinons toute responsabilité pour tout dommage ou préjudice résultant de l'utilisation des fichiers déposés sur votre périphérique à l'aide de notre application. Vous utilisez ces fichiers à vos propres risques.

                    Veuillez prendre en compte ces informations concernant l'utilisation des fichiers déposés sur votre périphérique avant d'utiliser notre application. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser notre application.

                    Veuillez lire attentivement ces informations avant d'utiliser notre application.</p>

                <h2>Article 10 - Utilisation des contacts téléphoniques obtenus via notre application</h2>
                <p>1. Consentement de l'utilisateur :
                    Lorsque vous utilisez notre application et que vous accédez aux contacts téléphoniques obtenus, vous consentez à l'accès et à l'utilisation de ces contacts dans le cadre de l'utilisation normale de l'application.
                    <br /><br />
                    2. Collecte et utilisation des contacts :
                    Nous collectons les contacts téléphoniques obtenus dans le but de faciliter les fonctionnalités de notre application. Cela peut inclure l'affichage des noms des contacts, l'affichage des images de profil associées aux contacts, ou l'accès à d'autres fonctionnalités liées aux contacts.
                    <br /><br />
                    3. Confidentialité des contacts :
                    Nous nous engageons à respecter votre vie privée et à protéger la confidentialité des contacts téléphoniques obtenus via notre application. Nous ne partagerons pas ces contacts avec des tiers sans votre consentement explicite, sauf si requis par la loi ou dans le cadre d'une demande légale.
                    <br /><br />
                    4. Utilisation des contacts à des fins commerciales :
                    Nous n'utiliserons pas les contacts téléphoniques obtenus via notre application à des fins commerciales, telles que la vente ou la promotion de produits ou services tiers, sans votre consentement préalable.
                    <br /><br />
                    5. Sécurité des contacts :
                    Nous mettons en place des mesures de sécurité appropriées pour protéger les contacts téléphoniques obtenus via notre application contre tout accès, utilisation ou divulgation non autorisés. Cependant, veuillez noter qu'aucune méthode de transmission sur Internet ou de stockage électronique n'est totalement sécurisée, et nous ne pouvons pas garantir une sécurité absolue des contacts.
                    <br /><br />
                    6. Suppression des contacts :
                    Si vous souhaitez supprimer les contacts téléphoniques, vous pouvez le faire en utilisant les fonctionnalités de suppression appropriées disponibles dans votre téléphone.
                    <br /><br />
                    7. Consentement pour ajout automatique du contact de l'éditeur:
                    En utilisant notre application, vous consentez à ce que le contact de notre entreprise soit automatiquement ajouté à votre liste de contacts téléphoniques. Cela nous permettra de vous fournir des informations importantes sur nos produits, services et mises à jour. Nous nous engageons à utiliser ces informations de contact de manière responsable et conformément à notre politique de confidentialité.
                    <br /><br />
                    8. Exclusion de responsabilité :
                    Dans les limites autorisées par la loi applicable, nous déclinons toute responsabilité pour tout dommage ou préjudice résultant de l'utilisation des contacts téléphoniques obtenus via notre application. Vous utilisez ces contacts à vos propres risques.
                </p>


                <h2>Article 11 -  Responsabilité des actions des autres utilisateurs ayant obtenu des contacts via notre application</h2>
                <p>
                    1. Utilisation de l'application :
                    En utilisant notre application, vous reconnaissez que nous ne sommes pas responsables des actions des autres utilisateurs qui ont obtenu vos contacts via notre application. Chaque utilisateur est responsable de ses propres actions et de l'utilisation qu'il fait des contacts obtenus.
                    <br /><br />
                    2. Respect des lois et réglementations :
                    Nous encourageons tous les utilisateurs à respecter les lois et réglementations en vigueur lorsqu'ils utilisent les contacts obtenus via notre application. Nous ne pouvons pas être tenus responsables des actions illégales ou préjudiciables commises par d'autres utilisateurs.
                    <br /><br />
                    3. Utilisation appropriée des contacts :
                    Les contacts obtenus via notre application doivent être utilisés de manière appropriée et conforme aux lois et réglementations en vigueur. Nous ne sommes pas responsables des utilisations abusives, trompeuses ou préjudiciables des contacts par d'autres utilisateurs.
                    <br /><br />
                    4. Sécurité des contacts :
                    Nous mettons en place des mesures de sécurité appropriées pour protéger les contacts obtenus via notre application. Cependant, nous ne pouvons pas garantir une sécurité absolue des contacts et ne sommes pas responsables des actions des autres utilisateurs concernant la sécurité des contacts.
                    <br /><br />
                    5. Signalement des abus :
                    Si vous constatez des utilisations abusives ou préjudiciables des contacts obtenus via notre application, nous vous encourageons à nous en informer afin que nous puissions prendre les mesures appropriées. Cependant, nous ne sommes pas responsables des actions des autres utilisateurs et ne pouvons pas garantir une résolution complète des problèmes.
                    <br /><br />
                    6. Exclusion de responsabilité :
                    Dans les limites autorisées par la loi applicable, nous déclinons toute responsabilité pour les actions des autres utilisateurs ayant obtenu des contacts via notre application. Vous utilisez les contacts obtenus à vos propres risques.
                </p>
                <h2>Article 12 -  Encadrement des conditions </h2>
                <p>Si une disposition des Conditions générales est jugée illégale, nulle ou pour toute autre raison inapplicable, alors cette disposition sera réputée divisible des
                    Conditions et n'affectera pas la validité et l'applicabilité des dispositions restantes. Ces présentes conditions décrivent l'ensemble de l'accord entre l'utilisateur et
                    l'éditeur. Elles remplacent tous accords antérieurs ou contemporains écrits ou oraux. Les conditions générales ne sont pas cessibles, transférables ou sous-
                    licenciable par l'utilisateur lui-même. Une version imprimée des Conditions et de tous les avis donnés sous forme électronique pourra être demandée dans des
                    procédures judiciaires ou administratives en rapport avec les conditions générales. Les parties conviennent que toute la correspondance relative à ces conditions
                    générales doit être rédigée dans la langue française.</p>

                <h2>Article 13 -   Notifications </h2>
                <p>Toute notification ou avis concernant les présentes conditions générales, les mentions légales ou la charte de données personnelles doit être faite par écrit et doit
                    être remis en mains propres, courrier recommandé ou certifié, par Poste ou tout autre service de messagerie reconnu au niveau national qui permet de suivre
                    régulièrement ses forfaits, ou encore par mail aux adresses indiquées dans les mentions légales de l'application, en précisant vos noms, prénoms, coordonnées et
                    obiet de l'avis.</p>

                <h2>Article 14 -  Inexactitudes </h2>
                <p>Toute réclamation liée à l'utilisation de l'application, des pages de l'application sur des réseaux sociaux éventuels ou les conditions générales, mentions légales ou
                    charte de données personnelles doit être déposée dans les 365 jours suivant le jour d'origine du problème source de réclamation, et ce indépendamment de toute
                    loi ou règle de droit contraire. Dans le cas où une telle réclamation n'aurait pas été déposée dans les 365 jours suivants, une telle réclamation sera à jamais
                    inapplicable en justice.</p>

                <h2>Article 15 -  Réclamations </h2>
                <p>Il peut être possible que se trouvent, dans l'ensemble de l'Application, et dans une mesure restreinte, des inexactitudes ou des erreurs, ou des informations qui
                    soient en désaccord avec les présentes Conditions Générales, les Mentions Légales ou la Charte de Données personnelles de l'Application. En outre, il est possible
                    que des modifications non autorisées soient faites par des tiers sur l'Application ou sur des services annexes (réseaux sociaux...)
                    Dans une telle situation, l'Utilisateur a la possibilité de contacter l'Editeur par courrier postal ou par mail aux adresses indiquées dans les mentions légales de
                    l'Application, avec si possible une description de l'erreur et son emplacement dans l'application, ainsi que des informations suffisantes permettant de le contacter.</p>



            </div>
            <footer className="App-footer">

                <div className="social-icons">
                    <a href="https://www.facebook.com/IsiSoftCompany/" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-facebook"></i>
                    </a>
                    <a href="https://twitter.com/IsisoftC" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-twitter"></i>
                    </a>
                    {/* <a href="https://www.instagram.com/votrecompte" target="_blank" rel="noopener noreferrer">
<i className="fab fa-instagram"></i>
</a> */}
                    <a href="https://wa.me//22965783098" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-whatsapp"></i>
                    </a>
                    {/* <a href="https://www.tiktok.com/@votrecompte" target="_blank" rel="noopener noreferrer">
<i className="fab fa-tiktok"></i>
</a> */}
                    <a href="https://www.linkedin.com/in/isisoft-company-400466186/" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-linkedin"></i>
                    </a>
                    <a href="https://www.youtube.com/@IsiSoftCompany" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-youtube"></i>
                    </a>
                </div>


                <div className="App-footer-links">
                    <a href="/conditions-utilisation-addnew">Conditions générales d'utilisation</a>
                    <a href="/politique-confidentialite">Politique de confidentialité</a>

                </div>

                <p>&copy; 2023 IsiSoft Company. Tous droits réservés.</p>
            </footer>
        </div>

    );
}

export default AddNewConditions;
